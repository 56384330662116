import { SignInContext } from '@/contexts/SignInContext';
import { useContext, useState } from 'react';
import { useApiError } from 'shared-components';
import { MicrosoftBtn } from 'shared-components/components/MicrosoftButton';

export const SignInWithMicrosoft = ({
  onDone,
  variant = 'color',
}: {
  onDone?: () => void;
  variant?: 'color' | 'dark';
}) => {
  const [isLoading, setIsLoading] = useState(false);

  const { handleError } = useApiError();

  const signInIdp = useContext(SignInContext);

  const handleSignIn = (accessToken: string) => {
    setIsLoading(true);

    if (!signInIdp) {
      return;
    }

    signInIdp({
      identity: {
        access_token: accessToken,
        provider: 'microsoft',
      },
    })
      .then(() => {
        if (onDone) {
          onDone();
        }
      })
      .catch(handleError)
      .finally(() => setIsLoading(false));
  };

  return (
    <MicrosoftBtn
      isLoading={isLoading}
      onAccept={handleSignIn}
      variant={variant}
    />
  );
};
